import {
  BillingSummary,
  DirectDebitSummary,
  InboundSwitchTracker,
} from '@ovotech/energy-cx';
import {
  Col,
  Margin,
  Modal,
  Row,
  Stack,
  VisuallyHidden,
} from '@ovotech/nebula';
import { Sandwich, useAnalytics } from '@ovotech/ui-tools';

import React, { Fragment, useEffect, useState } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom-v5';
import {
  LinkReferrer,
  redirectToMobileStore,
} from '@/src/utils/mobileStoreRedirect';
import { HighTrafficInterceptPage } from './components/InterceptPage/HighTrafficInterceptPage';
import { useHighTrafficInterceptPageViewed } from './components/InterceptPage/useHighTrafficInterceptPageViewed';
import { UsageAndMeterReadWidgets } from './components/UsageRow/UsageAndMeterReadRow';
import { MajorIncidentGeneralBanner } from './components/mi-banners';
import {
  ActionCard,
  DisplayFlex,
  ErrorBoundary,
  PageWithNavigation,
  PageWithoutNavigationAndSectionPadding,
  Section,
  SwitchWithNotFound,
} from '@/src/components';
import { NestedDisplayFlex } from '@/src/components/layout';
import { HOME_SCREEN, ONBOARDING } from '@/src/constants/analytics-subjects';
import {
  ACCOUNT_INFO_ERROR,
  ACTION_CENTRE_ERROR,
  BILLING_SUMMARY_WIDGET_ERROR,
  DD_WIDGET_ERROR,
} from '@/src/constants/error-section-ids';
import {
  HIDE_ACTION_CENTRE,
  HIGH_TRAFFIC_INTERCEPT_PAGE,
  SHOW_POWER_MOVE,
} from '@/src/constants/feature-flags';
import { ROUTE_HOME } from '@/src/constants/routes';
import { sandwichIds } from '@/src/constants/sandwich-ids';
import { TIMELINE_SECTION_ID } from '@/src/constants/sectionIds';
import {
  OptimizelyFeature,
  useDecision,
  useFeature,
} from '@/src/utils/optimizely';
import {
  haveAllSuppliesBeenActive,
  areAllSuppliesPastWindow,
} from '@/src/utils/supplyPoints';
import { StyledModal } from '@/src/components/HighBalanceModal/HighBalanceModal';
import { HasDDModalContent } from '@/src/components/HighBalanceModal/HighBalanceModalContent/HasDDModalContent';
import { NoDDModalContent } from '@/src/components/HighBalanceModal/HighBalanceModalContent/NoDDModalContent';
import { BalanceSummary } from '@/src/components/BalanceSummary/BalanceSummary';
import SiteLoading from '@/src/components/Wrapper/SiteLoading';
import { useSupplyPointsQuery } from '@/src/api/kapi/use-supply-points-query';
import { useBalance } from '@/src/pages/Payments/hooks/useBalance';
import { useMarketingPreferencesQuery } from '@/src/api/kapi/use-marketing-preferences-query';
import { EmailConsentCard } from '@/src/components/ActionCard/components/EmailConsentCard';
import { MarketingChannel } from '@/src/constants/enums';
import { Status, StatusCard } from './components/StatusCard';
import styled from 'styled-components';

const StyledSVGSizeOverride = styled.div`
  svg {
    max-width: unset;
  }
`;

const InboundSwitchTrackerWrapper = styled.div`
  margin-top: ${({ theme }) => theme.space[8]};
`;

enum HighBalanceModal {
  None = '',
  HasDD = 'modal=balance_review_has_dd',
  NoDD = 'modal=balance_review_no_dd',
}

interface InLifeHomePageProps {
  allSuppliesArePastWindow: boolean;
}

const InLifeHomePage = (props: InLifeHomePageProps) => {
  const { allSuppliesArePastWindow } = props;

  const { balanceVariation, isFetched } = useBalance();

  const history = useHistory();
  const [variant] = useDecision(SHOW_POWER_MOVE);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState<HighBalanceModal>(
    HighBalanceModal.None,
  );

  const openModal = () => {
    setIsOpen(true);
    document.documentElement.setAttribute('style', 'overflow-y: hidden;');
  };

  const closeModal = () => {
    setIsOpen(false);
    history.push({
      search: ``,
    });
    document.documentElement.setAttribute('style', 'overflow-y: scroll;');
  };

  const generateModalContent = () => {
    if (history.location.search.includes(HighBalanceModal.HasDD)) {
      openModal();
      setModalContent(HighBalanceModal.HasDD);
    } else if (history.location.search.includes(HighBalanceModal.NoDD)) {
      openModal();
      setModalContent(HighBalanceModal.NoDD);
    } else {
      closeModal();
      setModalContent(HighBalanceModal.None);
    }
  };

  useEffect(() => {
    generateModalContent();
  }, [history.location.search]);

  const { dispatch } = useAnalytics();

  useEffect(() => {
    if (isFetched) {
      dispatch({
        name: HOME_SCREEN,
        type: 'page',
        properties: {
          powerMoveTest:
            variant.variationKey === 'variant_updated'
              ? 'test group'
              : 'control group',
          balanceComponentTest: balanceVariation,
        },
      });
    }
    // we only want to call dispatch once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceVariation, isFetched, variant.variationKey]);

  return (
    <PageWithNavigation title="Home">
      <MajorIncidentGeneralBanner />

      {allSuppliesArePastWindow ? (
        <Sandwich id={sandwichIds.homepage.actionCentre}>
          <OptimizelyFeature feature={HIDE_ACTION_CENTRE}>
            {isEnabled =>
              isEnabled ? null : (
                <ErrorBoundary errorId={ACTION_CENTRE_ERROR}>
                  <ActionCard utmSource="action-center" />
                </ErrorBoundary>
              )
            }
          </OptimizelyFeature>
        </Sandwich>
      ) : (
        <InboundSwitchTrackerWrapper data-testId="orion-wrapper-inbound-switch-tracker">
          <InboundSwitchTracker />
        </InboundSwitchTrackerWrapper>
      )}

      <div>
        <StyledModal
          as={Modal}
          isOpen={modalIsOpen}
          onClose={closeModal}
          title={<VisuallyHidden>Tariff Modal</VisuallyHidden>}
        >
          {modalContent === HighBalanceModal.HasDD ? (
            <HasDDModalContent />
          ) : (
            <NoDDModalContent />
          )}
        </StyledModal>
      </div>

      <Section id={TIMELINE_SECTION_ID}>
        <Fragment>
          <VisuallyHidden>
            <h1>Home</h1>
          </VisuallyHidden>
          <StyledSVGSizeOverride>
            <Margin bottom={6}>
              <Row isNested>
                <Col medium={6} as={NestedDisplayFlex}>
                  <ErrorBoundary errorId={ACCOUNT_INFO_ERROR}>
                    <BalanceSummary />
                  </ErrorBoundary>
                </Col>

                <Col medium={6} as={DisplayFlex}>
                  <ErrorBoundary errorId={DD_WIDGET_ERROR}>
                    <DirectDebitSummary />
                  </ErrorBoundary>
                </Col>
              </Row>
              <UsageAndMeterReadWidgets />
            </Margin>
          </StyledSVGSizeOverride>
        </Fragment>
        <Sandwich id={sandwichIds.homepage.accountTimeline}>
          <ErrorBoundary errorId={BILLING_SUMMARY_WIDGET_ERROR}>
            <BillingSummary />
          </ErrorBoundary>
        </Sandwich>
      </Section>
    </PageWithNavigation>
  );
};

const Energy = () => {
  const [highTrafficInterceptPageEnabled] = useFeature(
    HIGH_TRAFFIC_INTERCEPT_PAGE,
  );
  const [
    hasViewedHighTrafficInterceptPage,
    setHasViewedHighTrafficInterceptPage,
  ] = useHighTrafficInterceptPageViewed();

  const {
    data: marketingPreferencesData,
    isFetching: isMarketigPreferencesQueryFetching,
    isError: isMarketingPreferencesQueryError,
  } = useMarketingPreferencesQuery();

  const [isEmailConsentCardDismissed, setIsEmailConsentCardDismissed] =
    useState<boolean>(false);

  const [activationStatus, setActivationStatus] = useState<Status>(null);

  const { data, isError, isLoading } = useSupplyPointsQuery();

  if (isLoading || isMarketigPreferencesQueryFetching) {
    return (
      <PageWithNavigation title="Home">
        <Section>
          <SiteLoading />
        </Section>
      </PageWithNavigation>
    );
  }

  if (isError) {
    return (
      <PageWithNavigation title="Home">
        <Section />
      </PageWithNavigation>
    );
  }

  const supplies = data?.account.accountSupplyPoints ?? [];
  const isEmailMarketingOn =
    !isMarketingPreferencesQueryError &&
    !!(marketingPreferencesData ?? []).find(
      channel => channel === MarketingChannel.EMAIL,
    );

  const showEmailConsentCard =
    !isEmailMarketingOn && !isEmailConsentCardDismissed;

  if (!haveAllSuppliesBeenActive(supplies)) {
    return (
      <PageWithoutNavigationAndSectionPadding
        title="Onboarding"
        analytics={{ name: ONBOARDING }}
      >
        <Stack spaceBetween={8}>
          {showEmailConsentCard && (
            <EmailConsentCard
              onSuccess={() => setActivationStatus('success')}
              onError={() => setActivationStatus('error')}
              onDismiss={() => setIsEmailConsentCardDismissed(true)}
              existingPreferences={marketingPreferencesData ?? []}
              isFetching={isMarketigPreferencesQueryFetching}
            />
          )}
          <StatusCard status={activationStatus} />
        </Stack>
        <InboundSwitchTrackerWrapper data-testId="orion-wrapper-inbound-switch-tracker">
          <InboundSwitchTracker />
        </InboundSwitchTrackerWrapper>
      </PageWithoutNavigationAndSectionPadding>
    );
  }

  if (highTrafficInterceptPageEnabled && !hasViewedHighTrafficInterceptPage) {
    return (
      <HighTrafficInterceptPage
        hasInteracted={setHasViewedHighTrafficInterceptPage}
      />
    );
  }

  const allSuppliesArePastWindow = areAllSuppliesPastWindow(supplies);

  return <InLifeHomePage allSuppliesArePastWindow={allSuppliesArePastWindow} />;
};

const HomePage = () => {
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const referrer = searchParams.get(LinkReferrer.BASE);

  // Redirect to moblie-store if on a mobile device and OVO app not installed
  if (referrer === LinkReferrer.TYPE_EMAIL && redirectToMobileStore()) {
    return <SiteLoading />;
  }

  return (
    <SwitchWithNotFound>
      <Route exact path={ROUTE_HOME} component={Energy} />
    </SwitchWithNotFound>
  );
};

export default HomePage;
