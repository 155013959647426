import { SmartChargers } from './anytime-chargers';
import { isVehicleInList, volvoPartnershipVehicles } from './anytime-vehicles';

export type Criteria = {
  charger: SmartChargers;
  vehicle: {
    make: string;
    model: string;
    year: number;
  };
  hasSolar: boolean;
  hasVehicleInternet: boolean;
  featureFlags: {
    payAsYouDriveVolvo: boolean;
  };
};

export type IntegrationType = 'Indra' | 'Ohme' | 'KaluzaCaApp' | 'Volvo';

/**
 * This function presumes eligibility!
 * Passing it ineligible criteria will result in incorrect results
 */
export const getIntegrationType = (criteria: Criteria): IntegrationType => {
  if (
    criteria.featureFlags.payAsYouDriveVolvo &&
    isVehicleInList(volvoPartnershipVehicles)(
      criteria.vehicle.make,
      criteria.vehicle.model,
      criteria.vehicle.year
    ) &&
    criteria.hasSolar === false &&
    criteria.hasVehicleInternet === true
  ) {
    return 'Volvo';
  }

  if (criteria.charger === SmartChargers.Indra) {
    return 'Indra';
  }

  if (criteria.charger === SmartChargers.Ohme) {
    return 'Ohme';
  }

  return 'KaluzaCaApp';
};
