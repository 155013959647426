import { Card, Margin, TextLink } from '@ovotech/nebula';
import React from 'react';

import { Link } from 'react-router-dom-v5';
import { energyInsightsStrings } from '../../strings';
import { CarbonIntensityLevel } from '../../types';
import { GridContainer } from '../GridContainer';
import {
  StyledH1,
  StyledSubtextP,
  StyledCarbonIntensityHeaderCardContents,
  StyledCarbonIntensityPageHeadings,
  SubTitleComponentContainer,
  StyledTextLinkP,
} from './PageHeader.styled';

import { ROUTE_ENERGY_INSIGHTS } from '@/src/constants/routes';
import { CarbonIntensityHeaderWidget } from '@/src/pages/EnergyInsights/pages/CarbonIntensity/Components/CarbonIntensityHeaderWidget/CarbonIntensityHeaderWidget';

type Props = {
  mainTitle: string;
  subTitle?: string;
  currentIntensity?: CarbonIntensityLevel;
};

export const PageHeaderWithCarbonIntensity = ({
  mainTitle,
  subTitle,
  currentIntensity,
}: Props) => {
  return (
    <GridContainer>
      <Margin style={{ position: 'relative', overflow: 'hidden' }}>
        <Card>
          <StyledCarbonIntensityHeaderCardContents>
            <StyledCarbonIntensityPageHeadings>
              <StyledH1>{mainTitle}</StyledH1>
              <StyledSubtextP>{subTitle}</StyledSubtextP>
            </StyledCarbonIntensityPageHeadings>
            <CarbonIntensityHeaderWidget currentIntensity={currentIntensity} />
          </StyledCarbonIntensityHeaderCardContents>
        </Card>
      </Margin>
    </GridContainer>
  );
};

type GlProps = {
  mainTitle: string;
  subTitle?: string;
  subTitleComponent?: JSX.Element;
  showLink?: boolean;
  headerTestId?: string;
};

export const PageHeaderWithHubLink = ({
  mainTitle,
  subTitle,
  subTitleComponent,
  showLink = true,
  headerTestId,
}: GlProps) => (
  <GridContainer>
    <Margin top={8} style={{ width: '100%' }}>
      <Card data-testId={headerTestId}>
        {showLink ? (
          <TextLink to={ROUTE_ENERGY_INSIGHTS.HUB} as={Link}>
            <StyledTextLinkP>&#60;</StyledTextLinkP>
            {energyInsightsStrings.backToHub}
          </TextLink>
        ) : null}

        <StyledH1>{mainTitle}</StyledH1>
        <StyledSubtextP>{subTitle}</StyledSubtextP>
        <SubTitleComponentContainer>
          {subTitleComponent}
        </SubTitleComponentContainer>
      </Card>
    </Margin>
  </GridContainer>
);
