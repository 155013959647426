import { Margin, P, Strong, TextLink } from '@ovotech/nebula';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { PageHeaderWithHubLink } from '../../components';
import { energyInsightsStrings as strings } from '../../strings';
import { EnergyInsightsPageName } from '../../types';
import { PageWithNavigation } from '@/src/components';
import { energyInsightsTestIDs } from '../../testIDs';

const StyledList = styled.ol`
  list-style: decimal;
`;

const StyledLi = styled.li`
  padding-top: 1em;
`;

const Bullet = ({ children }: PropsWithChildren) => (
  <StyledLi>
    <P>{children}</P>
  </StyledLi>
);

export const EnergyInsightsTermsPage = () => {
  return (
    <PageWithNavigation
      title="OVO Energy Insights Terms And Conditions"
      analytics={{ name: EnergyInsightsPageName.Terms }}
    >
      <PageHeaderWithHubLink
        mainTitle={`${strings.productLegalName} Terms And Conditions`}
        subTitle="Last Updated: 31 July 2023"
        headerTestId={`${energyInsightsTestIDs.common.termsHeader}`}
      />
      <Margin horizontal={6} top={10}>
        <P>
          These terms and conditions (<Strong>Terms</Strong>) govern your use of{' '}
          {strings.productLegalName} and the provision of{' '}
          {strings.productLegalName} to you.
        </P>
        <StyledList>
          <Bullet>
            {strings.productLegalName} is designed to help you better understand
            how energy is used in your home and what changes you could make to
            reduce your energy consumption.
          </Bullet>

          <Bullet>
            Your personalised energy saving recommendations are based on data
            automatically transmitted to us through your smart meter which may
            be connected to other devices in your home, as well as any other
            data you submit to us through My OVO.
          </Bullet>

          <Bullet>
            Your personalised energy saving recommendations are produced based
            on data which we have obtained from your smart meter. The more
            regular the data feed, the more detailed your personalised energy
            saving recommendations will be. For a more granular and up-to-date
            breakdown of your energy usage, we recommend that you set your meter
            to send readings every half hour. If you have chosen daily or
            monthly readings, the results will be less granular than with half
            hourly readings. See the ‘How we use smart meter data’ section of
            our Privacy Policy for how you can set the frequency of your smart
            meter readings.
          </Bullet>

          <Bullet>
            {strings.productLegalName} is produced on an “as is” basis only. We
            do not warrant or represent that {strings.productLegalName} (either
            general or personalised) will be delivered free of any inaccuracies,
            interruptions, delays, omissions or errors (“Faults”), or that all
            Faults will be corrected. It is therefore intended to provide
            information and guidance only and we are not liable for any Faults.
            You assume responsibility for any decisions made or actions taken
            based on the information contained in or generated by{' '}
            {strings.productLegalName}. We will not be liable for any damages of
            any kind arising from the use of {strings.productLegalName}, whether
            direct, indirect, incidental, punitive or consequential.
          </Bullet>

          <Bullet>
            You understand that when providing {strings.productLegalName}, we
            are providing energy efficiency information for general information
            purposes only and do not provide professional advice. Any savings
            claims given to you are an estimate only and your actual savings
            will vary depending on your energy use and the changes you make to
            your energy habits. We are not responsible for any loss, damage or
            cost resulting from any action or decisions taken by you made in
            reliance on {strings.productLegalName}. You agree that you use{' '}
            {strings.productLegalName} at your own risk in these respects.
          </Bullet>

          <Bullet>
            We may use your personal data to provide you with{' '}
            {strings.productLegalName}. The way in which data is collected and
            used by us is set out in our privacy policy which you can access{' '}
            <TextLink href="https://www.ovoenergy.com/privacy-policy">
              here
            </TextLink>
            .
          </Bullet>

          <Bullet>
            The material and information contained within{' '}
            {strings.productLegalName} is for your personal use and may not be
            distributed or used for commercial purposes. All content, including
            but not limited to text, graphics, logos, images and software is
            owned by OVO or its third party service providers (as applicable).
            Any use, including but not limited to, the reproduction,
            modification, distribution, transmission, republication or display
            of the content (other than in your use of this service) is strictly
            prohibited.
          </Bullet>

          <Bullet>
            We can make changes to these Terms or {strings.productLegalName} at
            any time, including withdrawing or amending the product. The amended
            terms will be on our website. We will try to give you reasonable
            notice if we make any changes which are to your detriment.
          </Bullet>

          <Bullet>
            These Terms are governed by the laws of England and Wales if your
            property is in England or Wales, and in Scotland if your property is
            in Scotland. If there is any dispute between us, it will be dealt
            with by the courts of England and Wales if your property is in
            England or Wales, and by the courts of Scotland if your property is
            in Scotland.
          </Bullet>
        </StyledList>
      </Margin>
    </PageWithNavigation>
  );
};
